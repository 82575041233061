import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  CardMedia,
  TextField,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import ClearIcon from '@mui/icons-material/HighlightOff';

import TemplatesApi from './Templates.api';
import styleColors from '../colors.styles';
import associateSpark from '../assets/associate-spark.png';
import SpinningSpark from '../shared/components/SpinningSpark';
import { AlertsContext } from '../shared/alerts/Alerts.context';

const MAX_IMAGE_SIZE_IN_MB = 8;
const MAX_VIDEO_SIZE_IN_MB = 500;

export default function TemplateContentForm({
  template,
  messageError,
  onChange,
}) {
  const { showAlert } = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);
  const textFieldRef = useRef(null);

  const uploadMedia = async (file) => {
    const isImage = file.type.startsWith('image');
    const maxFileSize = isImage ? MAX_IMAGE_SIZE_IN_MB : MAX_VIDEO_SIZE_IN_MB;

    if (file.size > maxFileSize * 1024 * 1024) {
      showAlert(
        `${
          isImage ? 'Images' : 'Videos'
        } must be smaller than ${maxFileSize} MB`,
        'error'
      );
      return;
    }

    setLoading(true);
    try {
      const fileUrl = await TemplatesApi.uploadTemplateMedia(file);
      onChange((t) => ({
        ...t,
        source: fileUrl,
        type: isImage ? 'photo' : 'video',
      }));
    } catch (err) {
      console.error(`Failed to upload media: ${err}`);
      showAlert('Failed to upload media', 'error');
    } finally {
      setLoading(false);
    }
  };

  let filePreview = null;
  if (!loading && template.source) {
    filePreview = (
      <Box position="relative" sx={styles.mediaPreview}>
        <CardMedia
          src={template.source}
          component={template.type === 'photo' ? 'img' : 'video'}
          controls
        />

        <IconButton
          onClick={() => {
            onChange((t) => ({ ...t, source: null, type: 'text' }));
          }}
          sx={{
            position: 'absolute',
            padding: 0,
            top: -10,
            left: -10,
          }}
        >
          <ClearIcon
            titleAccess="Remove media"
            sx={{
              width: 25,
              height: 25,
              borderRadius: '50%',
              backgroundColor: 'white',
            }}
          />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={4}
      padding={2.5}
      boxShadow={1}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom={3}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            alt="Spark logo"
            src={associateSpark}
            sx={{ width: 42, height: 42, marginRight: 2 }}
          />

          <Typography variant="body1" fontWeight={600}>
            Page Name
          </Typography>
        </Box>

        {template.category === 'fitb' && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ backgroundColor: styleColors.base.walmart_blue }}
            onClick={() => {
              const start = textFieldRef.current.selectionStart;
              const end = textFieldRef.current.selectionEnd;

              onChange((t) => {
                const firstPart = t.message.slice(0, start).trimEnd();
                const secondPart = t.message.slice(end).trimStart();
                const newMessage = `${firstPart} {blank} ${secondPart}`.trim();

                const blankPosition =
                  firstPart.length +
                  newMessage.slice(firstPart.length).search('{blank}');

                textFieldRef.current.focus();
                setTimeout(() => {
                  textFieldRef.current.setSelectionRange(
                    blankPosition + 1,
                    blankPosition + 6
                  );
                }, 50);

                return { ...t, message: newMessage };
              });
            }}
          >
            Insert Blank
          </Button>
        )}
      </Box>

      <Box display="flex" flexDirection="column" paddingBottom={2}>
        <TextField
          multiline
          fullWidth
          minRows={4}
          inputRef={textFieldRef}
          sx={{ ...styles.postMessage }}
          placeholder="Enter post content..."
          value={template.message}
          onChange={(e) => {
            onChange((t) => ({
              ...t,
              message: e.target.value,
            }));
          }}
        />
        {messageError}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{ ...styles.uploadBtn }}
      >
        {loading ? (
          <SpinningSpark marginTop="0" />
        ) : (
          <>
            {filePreview}

            <Button
              fullWidth
              tabIndex={-1}
              variant="text"
              component="label"
              role={undefined}
              sx={{ opacity: 0.5, paddingY: 1 }}
              startIcon={<CloudUploadIcon />}
            >
              {template.source ? 'Replace media' : 'Add an image or video'}

              <VisuallyHiddenInput
                type="file"
                accept=".png,.jpg,.mp4,.mov"
                onChange={(event) => uploadMedia(event.target.files[0])}
              />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const styles = {
  postMessage: {
    borderRadius: 4,
    backgroundColor: '#F6F9FC',
    '& fieldset': { border: 'none' },
  },
  mediaPreview: {
    width: '50%',
    marginY: 1,
    borderRadius: 2,
  },
  uploadBtn: {
    padding: 1,
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#F6F9FC',
    // CSS trick to display the dotted border
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239597A8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
};

TemplateContentForm.propTypes = {
  template: PropTypes.shape({
    category: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    source: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  messageError: PropTypes.node,
};

TemplateContentForm.defaultProps = {
  messageError: null,
};
