import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Link,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContentCopy, Link as LinkIcon } from '@mui/icons-material';
import colorsStyles from '../colors.styles';

export default function TemplateItem({ template }) {
  const {
    id,
    type,
    source,
    picture,
    message,
    link,
    category,
    topic,
    subtopic,
  } = template;
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const replacePlaceHolders = (templateCategory, templateMessage) => {
    if (templateCategory !== 'fitb') return templateMessage;
    return templateMessage.split(/(\{[^}]+\})/g).map((part, index) => {
      if (/\{[^}]+\}/.test(part)) {
        // eslint-disable-next-line react/no-array-index-key
        return <BlankSpace key={index} placeholder={part.slice(1, -1)} />;
      }
      return part;
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(id);
    setOpenSnackbar(true);
  };

  const styles = {
    card: {
      padding: '16px',
      borderRadius: '18px',
    },
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '8px',
    },
    thumbnail: {
      width: '134px', // from Figma
      height: '134px',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxHeight: '134px',
      maxWidth: source !== null ? 'calc(100% - 150px)' : '100%',
      overflow: 'clip',
      overflowClipMargin: '10px',
    },
    messageText: {
      display: '-webkit-box',
      WebkitLineClamp: link !== null ? 5 : 7,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre-wrap',
    },
    linkContainer: { display: 'flex', alignItems: 'center', gap: '4px' },
    link: {
      color: colorsStyles.base.walmart_blue,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'inline-block',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    linkIcon: {
      transform: 'rotate(-45deg)',
      color: colorsStyles.text.gray,
    },
    idButton: { textTransform: 'none' },
    detailsContainer: { display: 'flex', justifyContent: 'space-between' },
    detailsContainerItem: { display: 'flex', alignItems: 'end', gap: '4px' },
    detailsTitle: {
      textTransform: 'uppercase',
      fontSize: '11px',
      lineHeight: 'normal',
      fontWeight: '800',
    },
    detailsContent: {
      fontSize: '14px',
      lineHeight: 'normal',
      fontWeight: '400',
    },
  };

  return (
    <>
      <Card sx={styles.card}>
        <Box sx={styles.container}>
          <Box display="flex" gap={2}>
            {source !== null && (
              <img
                style={styles.thumbnail}
                alt={`template ${id}`}
                src={type === 'video' ? picture : source}
              />
            )}
            <Box sx={styles.messageContainer}>
              <Box>
                <Typography
                  variant="body2"
                  component="span"
                  sx={styles.messageText}
                >
                  {replacePlaceHolders(category, message)}
                </Typography>
              </Box>
              {category === 'pl' && (
                <Box sx={styles.linkContainer}>
                  <LinkIcon sx={styles.linkIcon} />
                  <Link href={link} underline="hover" sx={styles.link}>
                    {link}
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
          <Stack gap={1}>
            <Box
              sx={{ ...styles.detailsContainerItem, cursor: 'pointer' }}
              onClick={copyToClipboard}
            >
              <Typography sx={styles.detailsTitle}>Template ID:</Typography>
              <Tooltip title="Click to copy the ID" placement="top" arrow>
                <Typography sx={styles.detailsContent}>{id}</Typography>
              </Tooltip>
              <ContentCopy fontSize="small" />
            </Box>
            <Box sx={styles.detailsContainer}>
              <Box sx={styles.detailsContainerItem}>
                <Typography sx={styles.detailsTitle}>Topic:</Typography>
                <Typography sx={styles.detailsContent}>{topic.name}</Typography>
              </Box>
              <Box sx={styles.detailsContainerItem}>
                <Typography sx={styles.detailsTitle}>Subtopic:</Typography>
                <Typography sx={styles.detailsContent}>
                  {subtopic.name}
                </Typography>
              </Box>
              <Box sx={styles.detailsContainerItem}>
                <Typography sx={styles.detailsTitle}>Date:</Typography>
                <Typography sx={styles.detailsContent}>
                  01/04/25 - 24/07/25
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        message="ID copied to clipboard"
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

function BlankSpace({ placeholder }) {
  return (
    <Box
      sx={{
        display: 'inline-block',
        borderRadius: '5px',
        paddingX: '5px',
        backgroundColor: colorsStyles.base.very_light_gray,
      }}
    >
      <Typography variant="body2" color={colorsStyles.text.gray}>
        {placeholder}
      </Typography>
    </Box>
  );
}

TemplateItem.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    source: PropTypes.string,
    picture: PropTypes.string,
    message: PropTypes.string,
    link: PropTypes.string,
    category: PropTypes.string,
    topic: PropTypes.shape({
      name: PropTypes.string,
    }),
    subtopic: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

BlankSpace.propTypes = {
  placeholder: PropTypes.string,
};

BlankSpace.defaultProps = {
  placeholder: 'BLANK',
};
