import React from 'react';
import PropTypes from 'prop-types';
import Unauthorized from '../auth/Unauthorized.page';
import config from '../config';

export default function AccessControl({
  name,
  requiredPermission,
  userPermissions,
  children,
}) {
  const { MENU_ITEMS } = config;
  const hasPermission =
    MENU_ITEMS.includes(name) &&
    userPermissions.some(
      (permission) => permission?.title === requiredPermission?.title
    );

  return hasPermission ? children : <Unauthorized />;
}

AccessControl.propTypes = {
  name: PropTypes.string.isRequired,
  requiredPermission: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  children: PropTypes.node.isRequired,
};
